export const environment = {
  baseEndpoint: 'https://api-dev.talkingstick.app/',
  buildNumber: "28962313",  
  versionNumber: "3.1.0",  
  environment_deploy: 'dev',
  rayGunKey: 'hqaZDpkENyp0WOVqpDhdg',
  authResultKey: '4c97e2d0-e050-4bf0-8b88-0e97573306f4',
  cryptoKey: '31acc3bb-8543-4b00-bd3e-3fb96d06c443',
  posthogToken: 'phc_FxfNWKWqYxa9nm24KsWccHzMR776akapxZWqhHjFTGJ',
  orgCode: 'sk',
  appCode: 'ts',
  locizeProjectId: '714c2f9a-6c84-4052-b7f0-1e6469e617d7',
  appStoreUrls: {
    sk: {
      ios: 'itms-beta://beta.itunes.apple.com/v1/app/1610784906',
      androidPlay: 'https://play.google.com/store/apps/details?id=app.talkingstick',
      androidMarket: 'market://details?id=app.talkingstick',
    },
    burns: {
      ios: 'itms-beta://beta.itunes.apple.com/v1/app/6670217457',
      androidPlay: 'https://play.google.com/store/apps/details?id=app.theburnsway',
      androidMarket: 'market://details?id=app.theburnsway',
    },
  },

  firebaseConfig: {
    apiKey: 'AIzaSyCLDHbBwV_P4NaoQKLKDw5YFHUpUmVD_14',
    authDomain: 'talking-stick-dev-f04da.firebaseapp.com',
    projectId: 'talking-stick-dev-f04da',
    storageBucket: 'talking-stick-dev-f04da.appspot.com',
    messagingSenderId: '777694147085',
    appId: '1:777694147085:web:c35f67b3a28e2514295b44',
    vapidKey: 'BMkP8WV0peP5ZBQbr6jiFatOC2NLUWBd-IvJrlYYBzD6yqRHUXaE2S_5ftt2yD7el7krAYvinfMfzAKWiy_IcHU',
  },
};
